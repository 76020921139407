import { ChangeEvent, useState } from "react";

export default  function usePickFile() {
    const [value, setValue] = useState<File | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>();
  
    const handleReset = () => {
      setValue(undefined);
      setLoading(false);
    }

    const createInput = (onFinish?: (file: File) => void) => {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = '.pdf,image/*';
      input.style.display = 'none';

      setValue(undefined);
      setLoading(true);

      const handleCancel = () => {
        setLoading(false);
        input.remove();
      }
    
      input.onchange = async (event) => {
        try {
          const ev = event as unknown as ChangeEvent<HTMLInputElement>;

          const file = ev.target.files?.[0];
          if (!file) throw new Error("Not file");
          
          onFinish?.(file);
        } catch (e) {
          console.log(e);
        } finally {
          handleCancel();
        }
      };

      input.oncancel = handleCancel;
      input.click();
    }
  
    const handleUploadFile = async () => {
      createInput((file) => setValue(file));
    };

    const handleUploadFilePromise = async () => {
      return new Promise<File>((resolve) => {
        createInput((file) => resolve(file));
      })
    }
  
    return {
      value,
      loading,
      handleUploadFile,
      handleReset,
      handleUploadFilePromise,
    }
  }