import { FrameType } from 'atoms/camera-frame-selector';
import { ButtonListItem } from '../button-list';

export interface ItemType extends ButtonListItem {
  getDocsId?: string;
  frameType: FrameType;
}

export default [
  {
    key: '0', value: '0', getDocsId: '10', text: 'upload-docs.insurence', selected: true, frameType: 'landscape',
  },
  {
    key: '1', value: '1', getDocsId: '3', text: 'upload-docs.dl', frameType: 'landscape',
  },
  {
    key: '2', value: '2', getDocsId: '2', text: 'upload-docs.trade-in-title', frameType: 'portrait',
  },
  {
    key: '3', value: '3', getDocsId: '5', text: 'upload-docs.w2', frameType: 'landscape',
  },
  {
    key: '4', value: '4', getDocsId: '6', text: 'upload-docs.pay-stub', frameType: 'portrait',
  },
  {
    key: '5', value: '5', getDocsId: '6', text: 'upload-docs.bank-statement', frameType: 'portrait',
  },
  {
    key: '6', value: '6', getDocsId: '1', text: 'upload-docs.ssn', frameType: 'landscape',
  },
  {
    key: '7', value: '7', text: 'upload-docs.receipt', frameType: 'portrait',
  },
  {
    key: '8', value: '8', getDocsId: '7', text: 'upload-docs.proof-residence', frameType: 'portrait',
  },
  {
    key: '9', value: '9', getDocsId: '4', text: 'upload-docs.employment-letter', frameType: 'portrait',
  },
  {
    key: '10', value: '10', text: 'upload-docs.id', frameType: 'landscape',
  },
  {
    key: '11', value: '11', getDocsId: '11', text: 'upload-docs.total-loss', frameType: 'portrait',
  },
  {
    key: '12', value: '12', getDocsId: '12', text: 'upload-docs.draft-check', frameType: 'landscape',
  },
  {
    key: '13', value: '13', text: 'upload-docs.otd-price', frameType: 'landscape',
  },
  {
    key: '14', value: '14', getDocsId: '13', text: 'upload-docs.proof-pension', frameType: 'landscape',
  },
  {
    key: '15', value: '15', getDocsId: '14', text: 'upload-docs.awards-letter', frameType: 'landscape',
  },
  {
    key: '16', value: '16', getDocsId: '15', text: 'upload-docs.trade-in-registration', frameType: 'landscape',
  },
  {
    key: '17', value: '17', getDocsId: 'score-disclosure', text: 'upload-docs.score-disclosure', frameType: 'landscape',
  },
  {
    key: '18', value: '18', getDocsId: '18', text: 'upload-docs.tag-registration', frameType: 'landscape',
  },
  {
    key: '19', value: '19', getDocsId: '19', text: 'upload-docs.invoice', frameType: 'portrait',
  },
  {
    key: '20', value: '20', getDocsId: '20', text: 'upload-docs.trage-appriasal', frameType: 'portrait',
  }
] as ItemType[];
